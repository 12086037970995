export const richSelectXL = {
  wrapper: 'relative',
  buttonWrapper: 'inline-block relative w-full',
  selectButton: 'w-full flex text-left justify-between items-center px-4 py-5 text-black transition duration-100 ease-in-out border rounded-2xl disabled:opacity-50 disabled:cursor-not-allowed outline-none',
  selectButtonLabel: 'block truncate',
  selectButtonPlaceholder: 'block truncate',
  selectButtonIcon: 'fill-current flex-shrink-0 ml-1 h-4 w-4',
  selectButtonClearButton: 'rounded flex flex-shrink-0 items-center justify-center absolute right-0 top-0 m-2 h-6 w-6 transition duration-100 ease-in-out',
  selectButtonClearIcon: 'fill-current h-3 w-3',
  dropdown: 'absolute w-full z-10 -mt-1 absolute border-b border-l border-r rounded-b bg-gray-100 shadow-sm z-10',
  dropdownFeedback: '',
  loadingMoreResults: '',
  optionsList: 'overflow-auto',
  searchWrapper: 'inline-block w-full',
  searchBox: 'inline-block w-full bg-white',
  optgroup: '',
  option: 'cursor-pointer',
  disabledOption: 'opacity-50 cursor-not-allowed',
  highlightedOption: 'cursor-pointer',
  selectedOption: 'cursor-pointer',
  selectedHighlightedOption: 'cursor-pointer',
  optionContent: '',
  optionLabel: 'truncate block',
  selectedIcon: 'fill-current h-4 w-4',
  enterClass: '',
  enterActiveClass: '',
  enterToClass: '',
  leaveClass: '',
  leaveActiveClass: '',
  leaveToClass: ''
}