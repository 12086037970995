<template lang="">
  <div class="content-box">
    <div class="title-area">
      <h2 class="title-b">설정 / 계정관리 / 계정 상세정보</h2>
    </div>

    <div class="form-box mt-sm">
      <div class="form-group-box">
        <div class="form-item row-type">
          <label class="form-title">아이디<span class="requisite">*</span></label>
          <div class="form-element change">
            <input type="text" placeholder="ID" class="element-input size-lg" v-model="managerID" :readonly="isCheckManagerID">
            
            <button v-if="!isCheckManagerID" type="button" class="btn-change" @click="checkIDDuplication()">중복검사</button>
            <button v-else type="button" class="btn-change" @click="resetIDDuplication()">재설정</button>
          </div>
          <p class="mt-1 px-3 text-xxs text-red-600" v-if="managerID==''">필수 입력 사항 입니다.</p>
          <p class="mt-1 px-3 text-xxs text-red-600" v-if="managerID!='' && !isCheckManagerID">중복확인을 해 주세요.</p>
        </div>
        <div class="form-item row-type">
          <label class="form-title">비밀번호<span class="requisite">*</span></label>
          <div class="form-element">
            <input type="password" placeholder="비밀번호" v-model="managerPassword" class="element-input size-lg">
          </div>
          <p class="mt-1 px-3 text-xxs text-red-600" v-if="managerPassword==''">필수 입력 사항 입니다.</p>
          <p class="mt-1 px-3 text-xxs text-red-600" v-if="!validatePasswd(managerPassword)">비밀번호를 확인해 주세요.(하나 이상의 문자,숫자,특수문자 8자리 이상)</p>
        </div>

        <div class="form-item row-type">
          <label class="form-title">비밀번호 확인<span class="requisite">*</span></label>
          <div class="form-element">
            <input type="password" placeholder="비밀번호" v-model="managerPasswordCheck" class="element-input size-lg">
          </div>
          <p class="mt-1 px-3 text-xxs text-red-600" v-if="managerPasswordCheck==''">필수 입력 사항 입니다.</p>
          <p class="mt-1 px-3 text-xxs text-red-600" v-if="managerPassword!=managerPasswordCheck">비밀번호를 확인해 주세요.</p>
        </div>
      </div>

      <div class="form-group-box">
        <div class="form-item row-type">
          <label class="form-title">이름<span class="requisite">*</span></label>
          <div class="form-element">
            <input type="text" placeholder="이름" v-model="managerName" class="element-input size-lg">
          </div>
          <p class="mt-1 px-3 text-xxs text-red-600" v-if="managerName==''">필수 입력 사항 입니다.</p>
        </div>
        <div class="form-item row-type">
          <label class="form-title">휴대폰<span class="requisite">*</span></label>
          <div class="form-element">
            <input type="text" placeholder="휴대폰" v-model="managerPhone" @keyup="validatePhone()" class="element-input size-lg">
          </div>
          <p class="mt-1 px-3 text-xxs text-red-600" v-if="managerPhone==''">필수 입력 사항 입니다.</p>
        </div>
        <div class="form-item row-type">
          <label class="form-title">이메일<span class="requisite">*</span></label>
          <div class="form-element">
            <input type="text" placeholder="이메일" v-model="managerEmail" @keyup="validateEmail()" class="element-input size-lg">
          </div>
          <p class="mt-1 px-3 text-xxs text-red-600" v-if="managerEmail==''">필수 입력 사항 입니다.</p>
          <p class="mt-1 px-3 text-xxs text-red-600" v-if="managerEmail!='' && !isValidataEmail">이메일 형식을 확인해 주세요.</p>
        </div>
      </div>

      <div class="form-group-box">
        <div class="form-item row-type">
          <label class="form-title">권한유형<span class="requisite">*</span></label>
          <div class="form-element">
            
            <t-rich-select
              :options="roleList"
              class="border border-solid rounded-md text-gray-700 border-gray-300 outline-none w-62"
              textAttribute="value"
              valueAttribute="id"
              :hideSearchBox="false"
              v-model="managerRole"
              :fixedClasses="richSelectXL"
            >
            </t-rich-select>
          </div>
          <p class="mt-1 px-3 text-xxs text-red-600" v-if="managerRole==''">필수 입력 사항 입니다.</p>
        </div>
      </div>

      <div class="form-group-box">
          <div class="form-item row-type size-wide">
              <label class="form-title">관리자 메모</label>
              <div class="form-element size-b">
                  <textarea name="" class="element-textarea" v-model="managerDescription" placeholder="메모"></textarea>
              </div>
          </div>
      </div>
    </div>

    <div class="title-area mt-40">
      <p class="title-c">단말기 알림 SMS</p>
      <div class="element-switch">
        <input class="checkbox-input" type="checkbox" id="smaple51-1" v-model="isAlert" />
        <label class="checkbox-label" for="smaple51-1"><span class="for-a11y">Toggle Checkbox</span></label>
      </div>
    </div>

    <div class="choice-group mt-s">
      <div class="element-checkbox checkbox-text">
        <input class="radio-input" type="checkbox" id="checkbox1" v-model="alertDetect" />
        <label class="checkbox-label" for="checkbox1">신규 불법 카메라 탐지</label>
      </div>
      <div class="element-checkbox checkbox-text">
        <input class="radio-input" type="checkbox" id="checkbox2" v-model="alertOffline" />
        <label class="checkbox-label" for="checkbox2">단말기 오프라인</label>
      </div>
      <div class="element-checkbox checkbox-text">
        <input class="radio-input" type="checkbox" id="checkbox3" v-model="alertMemo" />
        <label class="checkbox-label" for="checkbox3">위치 정보에 단말메모 사용</label>
      </div>
    </div>

    <div class="note-group">
      <div class="note-item">
        <p class="note-title">불법 카메라 탐지 알림 문구</p>
        <div class="note-content">
          <span class="note-value">{관리구역명}</span>
          <span class="note-value">{상세위치}</span>
          <span class="note-value" v-if="alertMemo">{단말메모}</span>
          <span class="note-value">{단말기명}</span>
          <input type="text" class="w-3/5 py-2 px-1 outline-none" v-model="textDetect" placeholder="에서 신규 불법 카메라가 탐지되었습니다. 확인 바랍니다." />
        </div>
      </div>
      <div class="note-item">
        <p class="note-title">단말기 오프라인 알림 문구</p>
        <div class="note-content">
          <span class="note-value">{관리구역명}</span>
          <span class="note-value">{상세위치}</span>
          <span class="note-value" v-if="alertMemo">{단말메모}</span>
          <span class="note-value">{단말기명}</span>
          <input type="text" class="w-3/5 py-2 px-1 outline-none" v-model="textOffline" placeholder="가 오프라인 상태입니다. 확인 바랍니다." />
        </div>
      </div>
    </div>

    <div class="page-btn-area">
      <button type="button" class="element-btn bg-red size-lg" @click="cancelRegist()">취소</button>
      <!-- <button type="button" class="element-btn bg-blue size-lg" @click="managerRegist()" :disabled="checkRegistDisable()">저장</button> -->
      <button type="button" class="element-btn bg-blue size-lg" @click="managerRegist()">저장</button>
    </div>
  </div>
</template>

<script>
import { requestApi } from "@/utils/api"
import { mapGetters, mapActions } from "vuex"
import { richSelectXL } from "@/references/fixedClass"

import { encryptString } from '@/utils/config'

export default {
  created () {
    this.companyGuid = this.getCompanyGuid
  },
  computed: {
    ...mapGetters(["getIsOverlay","getCurrentUser","getCompanyGuid"]),
  },
  data () {
    return {
      companyGuid: '',
      
      richSelectXL,
      roleList: [
        {id:'member',value:'운영자'},
        {id:'agent',value:'현장관리자'},
      ],
      managerID: '',
      managerName: '',
      managerPassword: '',
      managerPasswordCheck: '',
      managerPhone: '',
      managerEmail: '',
      managerRole: 'agent',
      managerDescription: '',
      
      isAlert: true,
      alertDetect: false,
      alertOffline: false,
      alertMemo: false,
      textDetect: '에서 신규 불법 카메라가 탐지되었습니다. 확인 바랍니다.',
      textOffline: '가 오프라인 상태입니다. 확인 바랍니다.',
      isValidataEmail: false,

      isCheckManagerID: false,
    }
  },
  methods: {
    ...mapActions(["chgIsOverlay","chgIsAlert"]),
    async checkIDDuplication () {
      if(this.managerID == '') {
        this.chgIsAlert({status:true,string:'ID를 입력해 주세요.'})
        return;
      }

      // const idReg = /^[A-Za-z0-9@$!%*#?&]{5,20}$/;
      const idReg = /^[A-Za-z0-9~!@#$%^&*()_+<>?]{5,20}$/;
      if(!idReg.test(this.managerID)) {
        // this.chgIsAlert({status:true,string:'아이디는 영문자로 시작하는 영문자 또는 숫자 5~20자 입니다.'})
        this.chgIsAlert({status:true,string:'아이디는 영문자 또는 숫자, 특수문자 5~20자 입니다.'})
        this.managerID = '';
        return;
      }
      
      let reqObj = {}
      reqObj.target = `/user/check/${this.managerID}`;
      reqObj.method = "get";
      reqObj.params = {};
      reqObj.data = {};
      reqObj.headers = { "accept":"application/json" };

      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        if(result.msg.retData) {
          this.chgIsAlert({status:true,string:'ID 중복입니다.'})
        }
        else this.isCheckManagerID = true
      }
    },
    resetIDDuplication () {
      this.isCheckManagerID = false
    },
    cancelRegist () {
      this.$router.push({name:'ManagerList'})
    },
    validateEmail () {
      if (/^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i.test(this.managerEmail)) this.isValidataEmail = true
      else this.isValidataEmail = false
    },
    validatePhone () {
      this.managerPhone = this.managerPhone.replace(/[^0-9]/g, '');
    },
    validatePasswd (str) {
      // let passwdReg = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
      let passwdReg = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[~!@#$%^&*()_+<>?])[A-Za-z\d~!@#$%^&*()_+<>?]{8,}$/;
      if(str == "" || !passwdReg.test(str)) return false
      else return true
    },

    checkRegistDisable () {
      let retVal = true;
      if(this.managerID !== "" 
      && this.managerPassword !== ""
      && this.managerName !== ""
      && this.managerPhone !== ""
      && this.managerEmail !== ""
      && this.isValidataEmail
      && this.isCheckManagerID
      ) retVal = false;
      return retVal;
    },

    managerRegist () {
      if(this.managerID === '') {
        this.chgIsAlert({status:true,string:'아이디를 입력해 주세요.'})
        return;
      }

      if(!this.isCheckManagerID) {
        this.chgIsAlert({status:true,string:'아이디 중복확인을 해주세요.'})
        return;
      }

      // let passwdReg = /^[a-zA-Z0-9]*$/;
      // let passwdReg = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
      // if(this.managerPassword == "" || !passwdReg.test(this.managerPassword)) {
      //   this.chgIsAlert({status:true,string:'비밀번호를 확인해 주세요.(하나 이상의 문자,숫자,특수문자 8자리 이상)'})
      //   return;
      // }

      if(this.managerPassword != this.managerPasswordCheck) {
        this.chgIsAlert({status:true,string:'비밀번호 확인이 맞지 않습니다.'})
        return;
      }

      if(!this.validatePasswd(this.managerPassword)) {
        this.chgIsAlert({status:true,string:'비밀번호를 확인해 주세요.(하나 이상의 문자,숫자,특수문자 8자리 이상)'})
        return
      }

      if(this.managerName === '') {
        this.chgIsAlert({status:true,string:'담당자 이름을 입력해 주세요.'})
        return;
      }

      let phoneReg = /^01([0|1|6|7|8|9])([0-9]{3,4})([0-9]{4})$/
      if(this.managerPhone === '' || !phoneReg.test(this.managerPhone)) {
        this.chgIsAlert({status:true,string:'휴대폰번호를 확인해 주세요.'})
        return;
      }

      if(this.managerEmail === '') {
        this.chgIsAlert({status:true,string:'이메일을 입력해 주세요.'})
        return;
      }

      if(!this.isValidataEmail) {
        this.chgIsAlert({status:true,string:'이메일 형식을 확인해 주세요.'})
        return;
      }

      this.managerRegistAction();
    },
    async managerRegistAction() {
      let reqObj = {}
      reqObj.target = "/manager/regist";
      reqObj.method = "post";
      reqObj.params = {};
      reqObj.data = {
        "alertDetect": this.alertDetect ? 'yes' : 'no',
        "alertOffline": this.alertOffline ? 'yes' : 'no',
        "alertMemo": this.alertMemo ? 'yes' : 'no',
        "companyGuid": this.companyGuid,
        "isAlert": this.isAlert ? 'yes' : 'no',
        "managerDescription": this.managerDescription,
        "managerEmail": encryptString(this.managerEmail),
        "managerID": encryptString(this.managerID),
        "managerName": encryptString(this.managerName),
        "managerPassword": encryptString(this.managerPassword),
        "managerPhone": encryptString(this.managerPhone),
        "managerRole": this.managerRole,
        "textDetect": this.textDetect,
        "textOffline": this.textOffline,
      };
      reqObj.headers = { "accept":"application/json" };

      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        this.chgIsAlert({status:true,string:'등록 했습니다.'})
        this.$router.push({name:'ManagerList'})
      }
    }
    
  }
}
</script>
<style lang="">
  
</style>